import shared from "theme/shared";
import { color } from "../color";

let theme = {
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
    text: {
      primary: "#323338",
      active: "#4fc3f7",
    },
    background: {
      paper: "#fff",
      default: "#f6fff6",
      overlay: {
        dark: "rgba(0, 0, 0, 0.72)",
        light: "rgba(255, 255, 255, 0.92)",
      },
      menu: {
        0: "#18202c",
      },
    },
    divider: "#404854",
    branding: {
      green: "#7AD97C",
    },
    white: {
      main: "#fff",
    },
    safety: {
      light: "#00e004",
      main: "#00e004",
      dark: "#00e004",
    },
    color,
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  action: {
    selected: "rgba(0,0,0,0.04)",
    hover: "rgba(0,0,0,0.06)",
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
};

theme = {
  ...theme,
  ...shared,
};

export default theme;
