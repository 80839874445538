export const color = {
  greenPastel: "#A6FA9F",
  greenGray: "#8D9986",
  pinkGhost: "#FEF7F6",
  pinkPure: "#F6A6AA",
  yellowBright: "#F7DB47",
  yellowDust: "#FEF7F6",
  yellowPure: "#8D9986",
  redMat: "#F46D48",
  redDark: "#9E2F50",
  purplePure: "#CB78F5",
  brownPure: "#7B3E27",
};
