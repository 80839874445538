import { useCallback } from "react";
import styled from "styled-components";

import { marginCSS, paddingCSS } from "components/Css";

const ButtonBase = ({ children, onClick, item, href, target, ...rest }) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(item);
    }
  }, [item, onClick]);

  if (href) {
    return (
      <ABase href={href} target={target} {...rest}>
        {children}
      </ABase>
    );
  } else {
    return (
      <BtnBase onClick={handleOnClick} {...rest}>
        {children}
      </BtnBase>
    );
  }
};

const ABase = styled.a`
  ${marginCSS};
  ${paddingCSS}
  display: inline-block;
  display: block;
`;

const BtnBase = styled.button`
  ${marginCSS};
  ${paddingCSS}
  display: inline-block;
  display: block;
`;

export default ButtonBase;
