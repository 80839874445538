const UNITS = 4;

export const bp = {
  xs: 0,
  sm: 690,
  md: 950,
  lg: 1440,
  xl: 1920,
};

export const device = {
  xs: `(min-width: ${bp.xs}px)`,
  sm: `(min-width: ${bp.sm}px)`,
  md: `(min-width: ${bp.md}px)`,
  lg: `(min-width: ${bp.lg}px)`,
  xl: `(min-width: ${bp.xl}px)`,
};

function spacing(units) {
  return units * UNITS;
}

const shared = {
  spacing,
  mixins: {
    activeOpacity: 0.72,
    maxNavWidth: "1600px",
    maxPageWidth: "1600px",
    maxPageWidthNarrow: "1000px",
  },
  hoverFix: "@media (hover: hover)",
  bp: {
    xs: `(min-width: ${bp.xs}px)`,
    sm: `(min-width: ${bp.sm}px)`,
    md: `(min-width: ${bp.md}px)`,
    lg: `(min-width: ${bp.lg}px)`,
    xl: `(min-width: ${bp.xl}px)`,
  },
};

export default shared;
