import React from "react";
import { Link } from "react-router-dom";

function NotFound({ to, title }) {
  return (
    <div>
      <h1>NOT FOUND</h1>
      <p>
        <Link to={to}>{title}</Link>
      </p>
    </div>
  );
}

export default NotFound;
