import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

import * as ROUTE from "./constants";

import AppNavigation from "./appNavigation";

import { Fullscreen, Main } from "layout";

import Login from "screens/Login";
import NotFound from "screens/NotFound";
import Home from "screens/Home";

class RootNavigation extends Component {
  render() {
    const { isAuthenticated } = this.props;

    if (!isAuthenticated) {
      return (
        <Routes>
          <Route exact path={ROUTE.ROOT} element={<Main />}>
            <Route index element={<Home />} />
          </Route>
          <Route
            path="*"
            element={<NotFound title="Back to login" to={ROUTE.ROOT} />}
          />
        </Routes>
      );
    }
    return <AppNavigation />;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.authenticated,
});

export default connect(mapStateToProps)(RootNavigation);
