import { takeLatest, put, all, call } from "redux-saga/effects";
// import { push } from "connected-react-router";

import { userSignin } from "containers/user/sagas";

import { APP_INIT_REQUEST } from "./constants";
import { appInitFailureAction, appInitSuccessAction } from "./actions";

function* appInitRequest() {
  //get user and settings
  try {
    yield call(userSignin, { settings: { rethrow: true } });
    yield put(appInitSuccessAction());
  } catch (err) {
    yield put(appInitFailureAction(err.message));
  }
}

export default function* watchApp() {
  yield all([takeLatest(APP_INIT_REQUEST, appInitRequest)]);
}
