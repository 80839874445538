import styled from "styled-components";
import ReactPlayer from "react-player/file";

const Video = (props) => {
  return (
    <ResponsiveWrapper>
      <ReactPlayer
        {...props}
        style={{ position: "absolute", top: 0, left: 0 }}
        width="100%"
        height="100%"
      />
    </ResponsiveWrapper>
  );
};

const ResponsiveWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

export default Video;
