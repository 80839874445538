// import { CreateMember } from "@components/createMember";
// import { ProposalList } from "@components/proposalList";
// import { useData } from "contexts/dataContext";

import SectionHero from "./SectionHero";
import SectionVideo from "./SectionVideo";
import SectionMembership from "./SectionMembership";
import SectionSocial from "./SectionSocial";
import SectionSheeshIcon from "components/Sheesh";

export default function Home() {
  // const { isMember, loading, account } = useData();

  return (
    <>
      <SectionHero />
      <SectionVideo />
      <SectionMembership isMember={true} />
      {/* {isMember && <ProposalList />} */}
      <SectionSocial />
      <SectionSheeshIcon />
    </>
  );
}
