import styled from "styled-components";
import { marginCSS, paddingCSS } from "components/Css";

const Box = styled.div`
  ${marginCSS};
  ${paddingCSS};
  ${({ sx }) => sx && sx};
`;

export default Box;
