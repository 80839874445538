import styled from "styled-components";
import { Outlet } from "react-router-dom";

// import Head from "components/Head";
import Navbar from "components/Navbar";
import { Footer } from "components/Footer";

const MainTemplate = ({ children }) => {
  return (
    <Main>
      {/* <Head /> */}
      <Navbar />
      <Outlet />
      <Footer />
    </Main>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export default MainTemplate;
