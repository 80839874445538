import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function Fullscreen(props) {
  return (
    <Root>
      <h1>fullscreen layout</h1>
      <Outlet />
    </Root>
  );
}

export default Fullscreen;
