import RootNavigation from "navigation/rootNavigation";
import { ThemeProvider } from "styled-components";
import { lightTheme, GlobalCss } from "theme";
import "assets/index.css";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalCss />
      <RootNavigation />
    </ThemeProvider>
  );
}

export default App;
