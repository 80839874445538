import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import React, { useCallback, useState } from "react";
import Logo from "components/Logo";
import Button from "components/Button";
import Box from "components/Box";

import { userSignInRequestAction } from "containers/user/actions";

const Navbar = () => {
  const account = useSelector((state) => state.user.account);
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const [isMember] = useState(false);

  const handleConnect = useCallback(() => {
    return dispatch(userSignInRequestAction());
  }, [dispatch]);

  return (
    <>
      <Container>
        <Box
          as="nav"
          px={4}
          py={1}
          sx={css`
            max-width: 1600px;
            min-height: 64px;
            display: flex;
            width: 100%;
            align-items: center;

            background-color: #fff;
            margin: 0 auto;
          `}
        >
          <Box
            sx={css`
              display: flex;
              justify-content: space-between;
              width: 100%;
            `}
          >
            <div className="flex flex-row">
              <Link to="/">
                <Logo />
              </Link>
            </div>

            {/* {account ? (
              <Button onClick={() => {}} title={account.substr(0, 10)} />
            ) : (
              <Button
                onClick={handleConnect}
                title={loading ? "loading..." : "connect"}
              />
            )} */}
          </Box>
        </Box>
      </Container>
      <Spacing isMember={isMember} />
    </>
  );
};

const Container = styled.header`
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Spacing = styled.div`
  width: 100%;
  height: ${({ isMember }) => (isMember ? 128 : 64)}px;
`;

export default Navbar;
