import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";

import * as ROUTE from "./constants";
import { appInitRequestAction } from "containers/app/actions";

import { Fullscreen } from "layout";

// Screens
import Dashboard from "screens/Dashboard";
import NotFound from "screens/NotFound";

const AppNavigation = (props) => {
  const { appInitRequest } = props;

  useEffect(() => {
    appInitRequest();
  }, [appInitRequest]);

  return (
    <Routes>
      <Route exact path={ROUTE.DASHBOARD} element={<Fullscreen />}>
        <Route index element={<Dashboard />} />
      </Route>
      <Route
        path="*"
        element={<NotFound title="Back to dashboard" to={ROUTE.DASHBOARD} />}
      />
    </Routes>
  );
};

const actions = (dispatch) => ({
  appInitRequest: () => dispatch(appInitRequestAction()),
});

export default connect(null, actions)(AppNavigation);
